<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="gzReport">
      <template slot="toolbar">
        <el-button class="addBtn" @click="createReport">生成报告</el-button>
      </template>
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
        <vm-search label="发送状态">
          <el-select v-model="search.reportState" clearable>
            <el-option :value="true" label="已发送"></el-option>
            <el-option :value="false" label="未发送"></el-option>
          </el-select>
        </vm-search>
      </template>
      <table-column v-if="false" prop="elevatorNo" :label="$t('elevator.no')" :width="120" align="center">
        <template #default="scope">
          <router-link :to="'/elevator/' + scope.row.elevatorId" target="_blank">{{scope.row.elevatorNo}}</router-link>
        </template>
      </table-column>
      <table-column
        prop="registerNo"
        label="注册代码"
        :width="200"></table-column>
      <table-column prop="elevatorName" :label="$t('elevator.name')"></table-column>
      <table-column prop="useUnit" label="使用单位" align="center"></table-column>
      <table-column prop="maintCom" label="维保单位" align="center"></table-column>
      <table-column prop="monitorStartTime" label="监测开始时间" :width="105" align="center"></table-column>
      <table-column prop="monitorEndTime" label="监测结束时间" :width="105" align="center"></table-column>
      <table-column prop="onlineRate" label="终端在线率" width="100px" align="center">
        <template #default="scope">
          <span v-if="scope.row.onlineRate !== null">{{scope.row.onlineRate}}%</span>
        </template>
      </table-column>
      <table-column prop="healthLevel" label="健康度" width="100px" align="center"></table-column>
      <table-column prop="reportState" label="报告状态" width="100px" align="center">
        <template #default="scope">
          <el-tag :type="scope.row.reportState ? 'success' : 'info'">
            {{scope.row.reportState ? "已发送" : "未发送"}}
          </el-tag>
        </template>
      </table-column>
      <table-column prop="createTime" label="生成时间" width="150px" align="center"></table-column>
      <table-column prop="reportTime" label="发送时间" width="150px" align="center"></table-column>
      <table-column :label="$t('common.operation')" :width="200" align="center" fixed="right" :tooltip="false">
        <template #default="scope">
          <el-button type="text" v-if="!scope.row.reportState">
            <router-link :to="'/monitorReport/' + scope.row.id + '?autoUpload=false'" target="_blank">查看</router-link>
          </el-button>
          <el-button v-if="!scope.row.reportState && (userName === 'superAdmin' || userName === 'admin')" type="text" @click="handleDelete(scope.row)">删除</el-button>
          <el-button v-if="scope.row.pdfUrl" type="text" @click="downPdf(scope.row)">下载PDF</el-button>
          <el-button v-if="scope.row.pdfUrl" type="text" @click="send(scope.row.id)">重新发送</el-button>
        </template>
      </table-column>
    </vm-table>
  </div>
</template>
<script>
  import auth from "@/util/auth";

  export default {
    data() {
      return {
        userName: auth.getUsername(),
        exportLoading: false,
        loading: false,
        showAside: false,
        search: {
          filter: "",
          reportState: "",
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageIndex) {
        this.loading = true;
        this.$refs.vmTable.getList(pageIndex);
        this.loading = false;
      },
      send(id){
        this.$http.get(window.config.integrationUrl + "zhpt/api/v1/uploadPdf/" + id).then(res => {
          this.$message.success(this.$t("elevator.tip.operateSuccess"));
        }).catch((error) => {
          this.$message.error(this.$t("elevator.tip.operateError") + ", " + error.response.data.message);
        });
      },
      downPdf(row) {
        window.open(row.pdfUrl);

        // if(row.pdfUrl===null){
        //   return;
        // }
        // this.exportLoading = true;
        // let params = {
        // };
        // let pdfPathArr =row.pdfUrl.split("/");
        // let fileName=pdfPathArr[2];
        // this.$api
        //   .exportFile("gzReport/download/pdf/"+row.id, params, 60)
        //   .then(res => {
        //     this.exportLoading = false;
        //     let blob = new Blob([res.data]);
        //     if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        //       navigator.msSaveBlob(blob, fileName);
        //     } else {
        //       let a = document.createElement("a");
        //       a.href = URL.createObjectURL(blob);
        //       a.download = fileName;
        //       a.style.display = "none";
        //       document.body.appendChild(a);
        //       a.click();
        //       URL.revokeObjectURL(a.href);
        //       document.body.removeChild(a);
        //     }
        //   })
        //   .catch(() => {
        //     this.exportLoading = false;
        //   });
      },
      handleDelete(row) {
        this.$api.deleteById("gzReport", row.id).then(res => {
          this.getList(-1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch((error) => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.deleteError") + "," + error.response.data.message);
          }
        });
      },
      createReport() {
        this.$http.get("gzReport/create").then(res => {
          this.$message.success(this.$t("elevator.tip.operateSuccess"));
          this.getList(1);
        }).catch((error) => {
          this.$message.error(this.$t("elevator.tip.operateError") + ", " + error.response.data.message);
        });
      },
    },
  };
</script>
